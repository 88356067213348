
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';
import * as firebase from 'firebase';
// import * as admin from 'firebase-admin';
import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireStorage } from 'angularfire2/storage';
import { firestore } from 'firebase';
import { finalize } from 'rxjs/operators';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { AngularFireModule } from 'angularfire2';
import { ToastData, ToastOptions, ToastyService } from 'ng2-toasty';
import { AngularFireFunctions } from '@angular/fire/functions';

export class AuthInfo {
  constructor(public $uid: string) { }

  isLoggedIn() {
    return !!this.$uid;
  }
}

@Injectable({
  providedIn: 'root'
})

export class ApisService {
  static UNKNOWN_USER = new AuthInfo(null);
  db = firebase.firestore();
  public authInfo$: BehaviorSubject<AuthInfo> = new BehaviorSubject<AuthInfo>(ApisService.UNKNOWN_USER);
  constructor(
    private fireAuth: AngularFireAuth,
    private adb: AngularFirestore,
    private storage: AngularFireStorage,
    private http: HttpClient,
    private translateService: TranslateService,
    private toastyService: ToastyService,
    private functions: AngularFireFunctions,
  ) { 
    this.checkAuth().then((res:any) => {
      console.log('Logged in as ' + res.email);
    })
  }

  translate(str) {
    const currentLang = this.translateService.currentLang;
    const returnValue = this.translateService.translations[currentLang][str];
    if (returnValue === undefined) {
      return this.translateService.translations.en_merch[str];
    } else {
      return returnValue;
    }
  }

  success(message) {
    const toastOptions: ToastOptions = {
      title: 'Éxito',
      msg: message,
      showClose: true,
      timeout: 2000,
      theme: 'default',
      onAdd: (toast: ToastData) => {
        console.log('Toast ' + toast.id + ' has been added!');
      },
      onRemove: function (toast: ToastData) {
        console.log('Toast ' + toast.id + ' has been removed!');
      }
    };
    // Add see all possible types in one shot
    this.toastyService.success(toastOptions);
  }

  error(message) {
    const toastOptions: ToastOptions = {
      title: 'Error',
      msg: message,
      showClose: true,
      timeout: 2000,
      theme: 'default',
      onAdd: (toast: ToastData) => {
        console.log('Toast ' + toast.id + ' has been added!');
      },
      onRemove: function (toast: ToastData) {
        console.log('Toast ' + toast.id + ' has been removed!');
      }
    };
    // Add see all possible types in one shot
    this.toastyService.error(toastOptions);
  }


  public checkAuth() {
    return new Promise((resolve, reject) => {
      this.fireAuth.auth.onAuthStateChanged(user => {
        // console.log(user);
        if (user) {
          localStorage.setItem('uid', user.uid);
          resolve(user);
        } else {
          this.logout();
          localStorage.clear();
          resolve(false);
        }
      });
    });
  }

  public checkEmail(email: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth.auth.fetchSignInMethodsForEmail(email).then((info: any) => {
        resolve(info);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public login(email: string, password: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth.auth.signInWithEmailAndPassword(email, password)
        .then(res => {
          if (res.user) {
            this.authInfo$.next(new AuthInfo(res.user.uid));
            resolve(res.user);
          }
        })
        .catch(err => {

          this.authInfo$.next(ApisService.UNKNOWN_USER);
          reject(`login failed ${err}`);
        });
    });
  }

  public logout(): Promise<void> {
    this.authInfo$.next(ApisService.UNKNOWN_USER);
    // this.db.collection('users').doc(localStorage.getItem('uid')).update({ "fcm_token": firebase.firestore.FieldValue.delete() })
    return this.fireAuth.auth.signOut();
  }


  public register(user, pwd: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {

      let originalUser = this.fireAuth.auth.currentUser;
      this.fireAuth.auth.createUserWithEmailAndPassword(user.email, pwd)
        .then(res => {
          if (res.user) {
            this.db.collection('users').doc(res.user.uid).set({
              id: res.user.uid,
              email: user.email,
              // password: pwd,
              name: user.name,
              surname: user.surname,
              phone: user.phone,
              dni: user.dni,
              type: user.type,
            }).then(setted => {
              this.fireAuth.auth.updateCurrentUser(originalUser).then(res2 => {
                resolve(res.user);
              })
            });
            // this.authInfo$.next(new AuthInfo(res.user.uid));
            // resolve(res.user);
          }
        })
        .catch(err => {
          // this.authInfo$.next(ApisService.UNKNOWN_USER);
          reject(`login failed ${err}`);
        });
    });
  }

  getAllUsers() {
    // this.adb.collectionGroup('process').get().subscribe(as)
    return this.adb.collection<any>('users')
    .snapshotChanges().pipe(
      map(actions => {

        return actions.map((a:any) => {
          let data = a.payload.doc.data();
          let id = a.payload.doc.id;
          return ({ id, ...data });
        });
      })
    );
    // return new Promise<any>((resolve, reject) => {
    //   this.adb.collection('users').get().subscribe(async (process) => {
    //     // console.log(process);
    //     let data = process.docs.map((element) => {
    //       let item = element.data();
    //       item.id = element.id;
    //       if (item && item.uid) {
    //         item.uid.get().then(function (doc) {
    //           item.uid = doc.data();
    //         });
    //       }
    //       return item;
    //     });
    //     resolve(data);
    //   }, error => {
    //     reject(error);
    //   });
    // });

  }

  getUserById(id) {
    return this.adb.doc<any>('users/'+id).valueChanges();
  }

  updateUserById(id, newUser): Promise<any> {
    // console.log(id);
    // console.log(newUser);
    return new Promise<any>((resolve, reject) => {
      this.db.doc(`users/${id}`).update({
        dni: newUser.dni,
        phone: newUser.phone,
        name: newUser.name,
        surname: newUser.surname,
      }).then(res => {
        console.log('subido');
        resolve(res);
      })
      .catch(err => {
        reject(`update failed -> ${err}`);
      });
    });
  }

  // public updateClientMail(userMail, pwd: string, newMail): Promise<any> {
  public updateClientMail(userMail, newMail) {
    return new Promise<any>((resolve, reject) => {

      let originalUser = this.fireAuth.auth.currentUser;
      this.fireAuth.auth.signInWithEmailAndPassword(userMail, newMail.password)
      .then(res => {
        if (res.user) {
          res.user.updateEmail(newMail.email).then((us:any) => {
            let done = this.updateDbEmail(res, newMail.email, originalUser);
            if(done) {
              resolve(res.user);
            } else {
              reject(`updated failed`);
            }
          })
        }
      })
      .catch(err => {
        reject(`login failed ${err}`);
      });
    });
  }

  updateDbEmail(userCredential, newMail, originalUser) {
    return firebase.firestore().doc(`users/${userCredential.user.uid}`).update({
      email: newMail,
    }).then(res => {
      console.log('subido');
      this.fireAuth.auth.updateCurrentUser(originalUser).then(res2 => {
        return true
        // resolve(userCredential.user);
      }, err =>{
        return false
      })
    }, err =>{
      return false
    });
  }

  sendRecoverEmail (email) {
    this.fireAuth.auth.languageCode = 'es';
    return this.fireAuth.auth.sendPasswordResetEmail(
      email,
      // { url: "https://smartsos-app.firebaseapp.com/__/auth/action" }
    );
  }


  public updatePassword(userMail, pwd, newPass) {
    return new Promise<any>((resolve, reject) => {
    let originalUser = this.fireAuth.auth.currentUser;
    this.fireAuth.auth.signInWithEmailAndPassword(userMail, pwd)
    .then(res => {
      if (res.user) {
        let user = res.user;
                        user.updatePassword(newPass).then(res2 => {
                          this.fireAuth.auth.updateCurrentUser(originalUser).then(res3 => {
                            // resolve(userCredential.user);
                            resolve(res2);
                            console.log("Password updated");  
                          })
                        })
                        .catch(err => {
                          reject(`update failed -> ${err}`);
                        });

        }
      })
      .catch(err => {
        // reject(`login failed ${err}`);
      });
    });
  }

  getCategories() {
    return this.adb.collection<any>('categories')
    .snapshotChanges().pipe(
      map(actions => {

        return actions.map((a:any) => {
          let data = a.payload.doc.data();
          let id = a.payload.doc.id;
          return ({ id, ...data });
        });
      })
    );
  }

  editCategories(id, categorie) {
    return new Promise<any>((resolve, reject) => {
      this.db.doc(`categories/${id}`).update({
        categorie: categorie,
      }).then(res => {
        console.log('subido');
        resolve(res);
      })
      .catch(err => {
        reject(`update failed -> ${err}`);
      });
    });

  }

  addCategorie(categorie) {
    return new Promise<any>((resolve, reject) => {
      this.db.collection(`categories`).add({
        categorie: categorie,
      }).then(res => {
        console.log('subido');
        resolve(res);
      })
      .catch(err => {
        reject(`update failed -> ${err}`);
      });
    });
  }


  getAllProcess() {
    // this.adb.collectionGroup('process').get().subscribe(as)
    return this.adb.collectionGroup<any>('process', ref => ref.orderBy('start_date', 'desc'))
    .snapshotChanges().pipe(
      map(actions => {

        return actions.map((a:any) => {
          let data = a.payload.doc.data();
          let id = a.payload.doc.id;
          let user_id = a.payload.doc._E.path.segments[a.payload.doc._E.path.segments.length - 3];
          return ({ id, user_id, ...data });
        });
      })
    );
    // return new Promise<any>((resolve, reject) => {
    //   this.adb.collectionGroup('process', ref => ref.orderBy('start_date', 'desc')).get().subscribe(async (process) => {
    //     // console.log(process);
    //     let data = process.docs.map((element:any) => {
    //       // console.log(element);
    //       let item = element.data();
    //       item.id = element.id;
    //       item.user_id = element._E.path.segments[6];
    //       if (item && item.uid) {
    //         item.uid.get().then(function (doc) {
    //           item.uid = doc.data();
    //         });
    //       }
    //       return item;
    //     });
    //     resolve(data);
    //   }, error => {
    //     reject(error);
    //   });
    // });

  }

  // public getProcessById(id): Promise<any> {
  public getProcessById(processId):any {

    return this.adb.collectionGroup<any>('process')
    .snapshotChanges().pipe(
      map(actions => {

        return actions.map((a:any) => {
          let data = a.payload.doc.data();
          let id = a.payload.doc.id;
          let user_id = a.payload.doc._E.path.segments[a.payload.doc._E.path.segments.length - 3];
          // if(a.type=='added'){
          //   user_id = a.payload.doc._E.path.segments[6];
          // } else {
          //   user_id = a.payload.doc._E.path.segments[1];
          // }
          if(id == processId) {
            return ({ id, user_id, ...data });
          }
        });
      })
    );
    
    // return new Promise<any>((resolve, reject) => {
    //   this.adb.collectionGroup('process').get().subscribe(async (review) => {
    //     let data = review.docs.map((element:any) => {
    //       let item = element.data();
    //       item.user_id = element._E.path.segments[6];
    //       item.id = element.id;
    //       if (item.id == id) {
    //         console.log('true')
    //             let trueItem = item;
    //             // console.log(trueItem)
    //             return trueItem;
    //       }
    //     });
    //     resolve(data);
    //   }, error => {
    //     reject(error);
    //   });
    // });
  }

  getProcessByUser(user_id) {
    return this.adb.collection<any>(`users/${user_id}/process`, ref => ref.orderBy('start_date', 'desc'))
    .snapshotChanges().pipe(
      map(actions => {
        return actions.map((a:any) => {
          let data = a.payload.doc.data();
          let id = a.payload.doc.id;
          return ({ id, ...data });
        });
      })
    );
  }


  addProcess(process, user_id) {
    return this.adb.collection(`users/${user_id}/process`).add(process);
  }

  updateTitleandCategorie(title, categorie, userId, processId) {
    let name = 'news.state';
    return this.adb.doc<any>(`users/${userId}/process/${processId}`).update({
      title: title,
      categorie: categorie
    }).then(res => {
      console.log('subido');
    });
  }

  updateDescription(description, userId, processId) {
    let name = 'news.state';
    return this.adb.doc<any>(`users/${userId}/process/${processId}`).update({
      description: description,
    }).then(res => {
      console.log('subido');
    });
  }
    
  addEvent(event, userId, processId, news, process) {
    let name = 'news.event';
    news.event.push(true);
    return this.adb.doc<any>(`users/${userId}/process/${processId}`).update({
      event: firestore.FieldValue.arrayUnion({
        date: moment(event.date).format('YYYY-MM-DDTHH:mm'),
        title: event.title,
      }),
      [name]: news.event
    }).then(res => {
      console.log('subido');
      this.callCloudFunction(process);
    });
  }

  deleteEvent(user_id, id, event, index, news) {
    let name = `news.event`
    news.event.splice(index, 1);

    return this.adb.doc(`users/${user_id}/process/${id}`).update(
      {
        event: firestore.FieldValue.arrayRemove(event),
        [name]: news.event
      }
    );

  }

  addUpdate(update, userId, processId, news, process) {
    let name = 'news.state_updates';
    news.state_updates.push(true);
    return this.adb.doc<any>(`users/${userId}/process/${processId}`).update({
      state_updates: firestore.FieldValue.arrayUnion({
        date: moment().format('YYYY-MM-DD'),
        description: update.description,
      }),
      [name]: news.state_updates
    }).then(res => {
      console.log('subido');
      this.callCloudFunction(process);
    });
  }

  deleteUpdate(user_id, id, update, index, news) {
    let name = `news.state_updates`
    news.state_updates.splice(index, 1);

    return this.adb.doc(`users/${user_id}/process/${id}`).update(
      {
        state_updates: firestore.FieldValue.arrayRemove(update),
        [name]: news.state_updates
      }
    );
  }

  updateState(state, completed, userId, processId, process) {
    let name = 'news.state';
    return this.adb.doc<any>(`users/${userId}/process/${processId}`).update({
      state: state,
      completed: completed,
      [name]: true
    }).then(res => {
      console.log('subido');
      this.callCloudFunction(process)
    });
  }

  //************* Upload Image ******************//
  uploadImage(file, title, userId, processId, news, process) {
    // Validation for Images Only
    // if (file.type.split('/')[0] !== 'image') {
    //   console.error('unsupported file type :( ')
    //   return false;
    // }
    console.log(file);
    let fileName = file.name;
    // The storage path
    const path = `files/${file.name}`;
    //File reference
    const fileRef = this.storage.ref(path);
    // The main task
    let task = this.storage.upload(path, file);

    return task.snapshotChanges().pipe(
      finalize(() => {
        let uploadedFileURL = fileRef.getDownloadURL();

        uploadedFileURL.subscribe(url => {
          if (url) {
            // this.updateUserImageProfile(url);
            this.addFile(url, title, userId, processId, news, process)
          }
        }, error => {
          console.error(error);
        });
      })
    )
    // ).subscribe();
  }

  addFile( url, title, userId, processId, news, process ) {
    let name = 'news.files';
    news.files.push(true);
    return this.adb.doc<any>(`users/${userId}/process/${processId}`).update({
      files: firestore.FieldValue.arrayUnion({
        date: moment().format('YYYY-MM-DD'),
        title: title,
        file: url
      }),
      [name]: news.files
    }).then(res => {
      console.log('subido');
      this.callCloudFunction(process)
    });
  }

  deleteFile(file, filename, user_id, id, index, news) {
    let name = `news.files`
    news.files.splice(index, 1);


    return this.storage.storage.refFromURL(filename).delete().then(res => {
      this.adb.doc(`users/${user_id}/process/${id}`).update(
        {
          files: firestore.FieldValue.arrayRemove(file),
          [name]: news.files
        }
      );  
    });

  }

  callCloudFunction(process) {
    // Use the function name from Firebase
    const callable = this.functions.httpsCallable('sendNotification');

    // Create an Observable and pass any data you want to the function
    const obs = callable({ process: process });

    obs.subscribe(async res => {
        console.log(res)
    });
  }



  public createAdminProfile(emails: string, pwd: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth.auth.createUserWithEmailAndPassword(emails, pwd)
        .then(res => {
          if (res.user) {
            this.db.collection('users').doc(res.user.uid).set({
              uid: res.user.uid,
              email: emails,
              type: 'admin',
            });
            this.authInfo$.next(new AuthInfo(res.user.uid));
            resolve(res.user);
          }
        })
        .catch(err => {

          this.authInfo$.next(ApisService.UNKNOWN_USER);
          reject(`login failed ${err}`);
        });
    });
  }


  public createVenue(informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('venue').doc(informations.uid).set(informations).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  alerts(title, message, type) {
    Swal.fire(
      title,
      message,
      type
    );
  }


  public createDriver(
    email: string,
    password: string,
    fullname: string,
    coverImage: string,
    descriptions: string,
    phone: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth.auth.createUserWithEmailAndPassword(email, password)
        .then(res => {
          if (res.user) {
            this.db.collection('users').doc(res.user.uid).set({
              uid: res.user.uid,
              email: email,
              fullname: fullname,
              coverImage: coverImage,
              descriptions: descriptions,
              fcm_token: '',
              lat: '',
              lng: '',
              phone: phone,
              status: 'active',
              type: 'delivery',
              id: res.user.uid,
              current: 'active'
            });
            resolve(res.user);
          }
        })
        .catch(err => {

          this.authInfo$.next(ApisService.UNKNOWN_USER);
          reject(`login failed ${err}`);
        });
    });
  }


  public getProfile(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('users').doc(id).get().subscribe((profile: any) => {
        resolve(profile.data());
      }, error => {
        reject(error);
      });
    });
  }

  public getRestReview(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('reviews', ref => ref.where('restId', '==', id)).get().subscribe(async (review) => {
        let data = review.docs.map((element) => {
          let item = element.data();
          item.id = element.id;
          if (item && item.uid) {
            item.uid.get().then(function (doc) {
              item.uid = doc.data();
            });
          }
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getAdmin(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('users', ref => ref.where('type', '==', 'admin')).get().subscribe(async (review) => {
        let data = review.docs.map((element) => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  getCurrencyCode() {
    return environment.general.code;
  }

  getCurrecySymbol() {
    return environment.general.symbol;
  }

  public getVenues(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('venue').get().subscribe((venue) => {
        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getUsers(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('users').get().subscribe((users) => {
        let data = users.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getUserProfile(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('users').doc(id).snapshotChanges().subscribe((users: any) => {
        resolve(users.data());
      }, error => {
        reject(error);
      });
    });
  }


  public getAllOrders(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('orders').get().subscribe((orders) => {
        let data = orders.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          item.vid.get().then(function (doc) {
            item.vid = doc.data();
            item.vid.id = doc.id;
          });
          item.uid.get().then(function (doc) {
            item.uid = doc.data();
            item.uid.id = doc.id;
          });
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  sendNotification(msg, title) {
    const body = {
      app_id: environment.onesignal.appId,
      included_segments: ['Active Users', 'Inactive Users"'],
      headings: { en: title },
      contents: { en: msg },
      data: { task: msg }
    };
    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Authorization', `Basic ${environment.onesignal.restKey}`)
    };
    return this.http.post('https://onesignal.com/api/v1/notifications', body, header);
  }

  public getVenueDetails(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('venue').doc(id).get().subscribe((venue: any) => {
        resolve(venue.data());
      }, error => {
        reject(error);
      });
    });
  }


  public getVenueUser(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('users').doc(id).get().subscribe((venue: any) => {
        resolve(venue.data());
      }, error => {
        reject(error);
      });
    });
  }

  public getVenueCategories(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('categories', ref => ref.where('uid', '==', id)).get().subscribe((venue) => {
        var data = venue.docs.map(element => {
          var item = element.data();
          item.id = element.id;
          return item;
        })
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getFoods(uid: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.db.collection('foods').doc(uid).collection('all').get().then((data) => {
        var users = data.docs.map(doc => {
          var item = doc.data();
          item.cid.get().then(function (doc) {
            item.cid = doc.data();
            item.cid.id = doc.id;
          });
          item.id = doc.id;
          return item;
        });
        resolve(users);
      }, error => {
        reject(error);
      });
    });
  }

  public addNewAddress(uid, id, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('address').doc(uid).collection('all').doc(id).set(param).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public addCoupon(id, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('offers').doc(id).set(param).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }


  public addBanner(id, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('banners').doc(id).set(param).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public addCity(id, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('cities').doc(id).set(param).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public getCities(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('cities').get().subscribe((venue: any) => {
        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public updateOffers(informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('offers').doc(informations.id).update(informations).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public getOffers(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('offers').get().subscribe((venue: any) => {
        // resolve(venue.data());
        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getBanners(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('banners').get().subscribe((venue: any) => {
        // resolve(venue.data());
        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getMessages(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('messages').doc(id).collection('chats').get().subscribe((messages: any) => {
        console.log(messages);
        let data = messages.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getMyAddress(uid: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.db.collection('address').doc(uid).collection('all').get().then((data) => {
        var users = data.docs.map(doc => {
          var item = doc.data();
          item.id = doc.id;
          return item;
        });
        resolve(users);
      }, error => {
        reject(error);
      });
    });
  }

  public createOrder(id, param): Promise<any> {
    param.vid = this.db.collection('venue').doc(param.vid);
    param.uid = this.db.collection('users').doc(param.uid);
    param.dId = this.db.collection('users').doc(param.dId);
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('orders').doc(id).set(param).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }
  
  public getMyOrders(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('orders', ref => ref.where('userId', '==', id)).get().subscribe(async (venue) => {
        let data = venue.docs.map(element => {
          let item = element.data();
          item.vid.get().then(function (doc) {
            item.vid = doc.data();
            item.vid.id = doc.id;
          });
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getRestOrders(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('orders', ref => ref.where('restId', '==', id)).get().subscribe((venue) => {
        let data = venue.docs.map(element => {
          let item = element.data();
          item.uid.get().then(function (doc) {
            item.uid = doc.data();
            item.uid.id = doc.id;
          });
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  public getOrderById(id): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.adb.collection('orders').doc(id).get().subscribe(async (order: any) => {
        let data = await order.data();
        await data.vid.get().then(function (doc) {
          data.vid = doc.data();
          data.vid.id = doc.id;
        });
        await data.uid.get().then(function (doc) {
          data.uid = doc.data();
          data.uid.id = doc.id;
        });
        if (data && data.dId) {
          await data.dId.get().then(function (doc) {
            data.dId = doc.id;
            data.dId = doc.data();
          });
        }
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }

  getDriverInfo(id): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.adb.collection('users').doc(id).snapshotChanges().subscribe(data => {
        console.log(data);
        resolve(data.payload.data());
      }, error => {
        reject(error);
      });
    });
  }

  public getDrivers(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('users', ref => ref.where('type', '==', 'delivery')).get().subscribe(async (venue) => {
        let data = venue.docs.map(element => {
          let item = element.data();
          item.id = element.id;
          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }


  public sendOrderToDriver(id, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('driverOrders').doc(id).set(param).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public addReview(param): Promise<any> {
    param.vid = this.db.collection('venue').doc(param.vid);
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('reviews').doc(Math.random().toString()).set(param).then((data) => {
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public updateVenue(informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('venue').doc(informations.uid).update(informations).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }


  public updateBanner(informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('banners').doc(informations.id).update(informations).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public updateCity(informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('cities').doc(informations.id).update(informations).then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public deleteBanner(informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('banners').doc(informations.id).delete().then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public deleteCity(informations: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('cities').doc(informations.id).delete().then((data) => {
        resolve(data);
      }, error => {
        reject(error);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public updateProfile(uid, param): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.db.collection('users').doc(uid).update(param).then((data) => {
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  }

  public getMyReviews(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb.collection('reviews', ref => ref.where('id', '==', id)).get().subscribe(async (review) => {
        let data = review.docs.map((element) => {
          let item = element.data();
          item.id = element.id;
          if (item && item.vid) {
            item.vid.get().then(function (doc) {
              item.vid = doc.data();
            });
          }

          return item;
        });
        resolve(data);
      }, error => {
        reject(error);
      });
    });
  }


  JSON_to_URLEncoded(element, key?, list?) {
    let new_list = list || [];
    if (typeof element == "object") {
      for (let idx in element) {
        this.JSON_to_URLEncoded(
          element[idx],
          key ? key + "[" + idx + "]" : idx,
          new_list
        );
      }
    } else {
      new_list.push(key + "=" + encodeURIComponent(element));
    }
    return new_list.join("&");
  }


  httpPost(url, body) {
    const header = {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/x-www-form-urlencoded')
        .set('Authorization', `Bearer ${environment.stripe.sk}`)
    };
    const order = this.JSON_to_URLEncoded(body);
    console.log(order);
    return this.http.post(url, order, header);
  }

  public updateOrderStatus(id, value): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
      this.adb.collection('orders').doc(id).update({ status: value }).then(async (order: any) => {
        resolve(order);
      }).catch(error => {
        reject(error);
      });
    });
  }

}
